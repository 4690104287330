// import Body from "./Components/Body";
import Header from "./Components/Header";
import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Franchise from "./Components/Franchise";
const Body = React.lazy(() => import("./Components/Body"));
const Franchise = React.lazy(() => import("./Components/Franchise"));

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<>...</>}>
                <Header />
                <Body />
              </React.Suspense>
            }
          />
          <Route
            path="/franchise"
            element={
              <React.Suspense fallback={<>...</>}>
                <Header />
                <Franchise />
              </React.Suspense>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
