import React from "react";
import classes from "./Header.module.scss";
import Logo from "../assets/logo.webp";
import { useNavigate, useLocation } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  let location = useLocation();
  const openNewPage = () => {
    navigate("/franchise");
  };
  const onHomeClick = () => {
    navigate("/");
  };
  return (
    <div className={classes.container}>
      {location.pathname === "/" && (
        <div className={classes.franchise_container}>
          <span>For Franchise Enquiry </span>{" "}
          <span className={classes.underline_text} onClick={openNewPage}>
            Click Here
          </span>
        </div>
      )}
      <div className={classes.header_container}>
        <img
          src={Logo}
          alt="logo"
          className={classes.logo}
          onClick={onHomeClick}
        />
        <button
          className={classes.button}
          onClick={() => window.open("https://www.onelink.to/lyrics", "_blank")}
        >
          Download App
        </button>
      </div>
    </div>
  );
}
